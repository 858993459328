import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useDispatch } from 'react-redux';
import { weelklyLoginTeacherAction } from '../../Redux/Action/TeacherAction';
import { weelklyLoginStudentAction } from '../../Redux/Action/studentAction';
import { convertTime } from '../../Util/HelperFunction';
import { weelklyLoginparentAction } from '../../Redux/Action/ParentAction';

const WeeklyLoginChart = ({ setWeekCount, selectedWeek, selecteDates, idval, type }) => {


  // Static data for fallback (optional)
  const staticData = {
    perWeekLogin: [
      { day: 'Mon', minutes: 0 },
      { day: 'Tue', minutes: 0 },
      { day: 'Wed', minutes: 0 },
      { day: 'Thu', minutes: 0 },
      { day: 'Fri', minutes: 0 },
      { day: 'Sat', minutes: 0 },
      { day: 'Sun', minutes: 0 },
    ],
  };

  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      id: idval,
      date: selecteDates,
    };

    setLoading(true);

    if (type === 'student') {
      calculateStudentWeek(payload);
    }else if( type === "parent"){
      calculateParenttWeek(payload);
    } else {
      calculateWeek(payload);
    }
  }, [dispatch, idval, selectedWeek, selecteDates, setWeekCount, type]);

  const calculateWeek = async (payload) => {
    const data = await dispatch(weelklyLoginTeacherAction(payload));
    if (data.payload.statusCode === 200) {
      setWeekCount(data.payload.data?.averageMinutes);
      const result = data.payload.data?.result || [];
      setChartData(result.map(event => ({
        day: event.day,
        minutes: Math.trunc(event.minutes),  // Use number for chart
      })));
    }
    setLoading(false);
  };

  const calculateStudentWeek = async (payload) => {
    const data = await dispatch(weelklyLoginStudentAction(payload));
    if (data.payload.statusCode === 200) {
      setWeekCount(data.payload.data?.averageMinutes);
      const result = data.payload.data?.result || [];
      setChartData(result.map(event => ({
        day: event.day,
        minutes: Math.trunc(event.minutes),  // Use number for chart
      })));
    }
    setLoading(false);
  };




  const calculateParenttWeek = async (payload) => {
    const data = await dispatch(weelklyLoginparentAction(payload));
    if (data.payload.statusCode === 200) {
      setWeekCount(data.payload.data?.averageMinutes);
      const result = data.payload.data?.result || [];
      setChartData(result.map(event => ({
        day: event.day,
        minutes: Math.trunc(event.minutes),  // Use number for chart
      })));
    }
    setLoading(false);
  };




  

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { minutes } = payload[0].payload;
      // console.log("minutes",minutes)
      return (
        <div className="custom-tooltip">
          {/* <p>Total Time</p> */}
          <p>{payload[0].payload.day}</p>
          <p>{convertTime(minutes)}</p>  {/* Show converted time here */}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={chartData.length > 0 ? chartData : staticData.perWeekLogin}>
        <XAxis dataKey="day" />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="minutes" fill="#4318ff" name="Total Time" radius={[10, 10, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WeeklyLoginChart;
