import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const tagsListAction = createAsyncThunk(
    'tags/list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/tag/list?search=${reqBody?.searchValue}&page=${reqBody.page ? reqBody.page :0}&limit=${reqBody?.limit == undefined ? "" : reqBody?.limit}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const tagsCreateAction = createAsyncThunk(
    'tags/create', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('tag/create', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const tagsUpdateAction = createAsyncThunk(
    'tags/update', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`/tag/update`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const tagsDeleteAction = createAsyncThunk(
    'tag/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/tag/delete?id=${reqBody?.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })