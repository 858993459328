import { createSlice } from "@reduxjs/toolkit";
import { DashboardListAction, RegistrationGraphAction, dashboardTopActivityAction, dashboardTopCategoryAction, dashboardTopSavedAction, dashboardTopTagsAction, dashboardTopVideosAction } from "../Action/DashBoardAction";


const initialState = {
    dashboardList:[],
    registrationGraph:[],
    topVideos:[],
    topCategory:[],
    topSaved:[],
    topActivity:[],
    topTags:[],
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(DashboardListAction.fulfilled, (state, action) => {
                state.dashboardList = action?.payload?.data;  
            })     
             
            .addCase(RegistrationGraphAction.fulfilled, (state, action) => {
                state.registrationGraph = action?.payload?.data;  
            })
             
            .addCase(dashboardTopVideosAction.fulfilled, (state, action) => {
                state.topVideos = action?.payload?.data;  
            })
        
            .addCase(dashboardTopCategoryAction.fulfilled, (state, action) => {
                state.topCategory = action?.payload?.data;  
            })
            
            .addCase(dashboardTopSavedAction.fulfilled, (state, action) => {
                state.topSaved= action?.payload?.data;  
            })
             
            .addCase(dashboardTopTagsAction.fulfilled, (state, action) => {
                state.topTags= action?.payload?.data;  
            })
            
            .addCase(dashboardTopActivityAction.fulfilled, (state, action) => {
                state.topActivity= action?.payload?.data;  
            })
                     
            
    },
});

export default dashboardSlice.reducer;








