import React, { lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Protectedroute from "./Redux/Protectedroute";
import PublicRoutesSuspense from "./Components/Layout/PublicRoutesSuspense";
import PrivateRoutesSuspense from "./Components/Layout/PrivateRoutesSuspense";
import 'react-loading-skeleton/dist/skeleton.css'
import PageNotFound from "./pages/PageNotFound";
import ProtectPublic from "./Components/common/ProtectPublic";
import ParentProfile from "./pages/ParentProfile";
// import { setupNotifications } from "./firebase";
// Lazy load the components
const Login = lazy(() => import("./pages/Login"));
const ManageSchools = lazy(() => import("./pages/ManageSchools"));
const Support = lazy(() => import("./pages/Support"));
const SupportDetail = lazy(() => import("./pages/Support-detail"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const ManageTags = lazy(() => import("./pages/ManageTags"));
const Teachers = lazy(() => import("./pages/Teachers"));
const PushNotifications = lazy(() => import("./pages/PushNotifications"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const ManageAdmins = lazy(() => import("./pages/ManageAdmins"));
const ManageContent = lazy(() => import("./pages/ManageContent"));
const Students = lazy(() => import("./pages/Students"));
const Parents = lazy(() => import("./pages/Parents"));
const ManageCategories = lazy(() => import("./pages/ManageCategories"));
const FAQs = lazy(() => import("./pages/FAQs"));
const Opt = lazy(() => import("./pages/Opt"));
const SchoolProfile = lazy(() => import("./pages/SchoolProfile"));
const TeacherProfile = lazy(() => import("./pages/TeacherProfile"));
const TeacherCollection = lazy(() => import("./pages/TeacherCollection"));
const StudentClassDetail = lazy(() => import("./pages/StudentClassDetail"));
const TeacherClassDetail  = lazy(() => import("./pages/TeacherClassDetail"));
const StudentProfile = lazy(() => import("./pages/StudentProfile"));
const StudentCollection = lazy(() => import("./pages/StudentCollection"));
const ParentCollection = lazy(() => import("./pages/ParentCollection"));
const AdminDetails = lazy(() => import("./pages/AdminDetails"));
const SingleVideo = lazy(() => import("./pages/SingleVideo"));
const ForgotPassword = lazy(() => import("./pages/ForgetPassword"));
const ForgotOpt = lazy(() => import("./pages/ForgotOpt"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const AdminResetPassword = lazy(() => import("./pages/AdminResetPassword"));






function App() {

  // useEffect(() => {
  //   setupNotifications()
  // }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

        <Route element={<ProtectPublic />}>
          <Route path="/" element={<PublicRoutesSuspense> <Login /></PublicRoutesSuspense>} />
          <Route path="/AdminResetPassword/:id" element={<PublicRoutesSuspense> <AdminResetPassword /></PublicRoutesSuspense>} />
          <Route path="/Otp/:id" element={ <PublicRoutesSuspense> <Opt /> </PublicRoutesSuspense>} />
          <Route path="/Forgot-Opt/:id" element={<PublicRoutesSuspense><ForgotOpt /></PublicRoutesSuspense>} />
          <Route path="/Reset-Password/:id/:tokenid" element={<PublicRoutesSuspense><ResetPassword /></PublicRoutesSuspense>} />
          <Route path="/Forgot-Password" element={<PublicRoutesSuspense><ForgotPassword /></PublicRoutesSuspense>} />
          </Route>
          <Route element={<Protectedroute />}>

   
            <Route path="/ManageCategories" element={<PrivateRoutesSuspense><ManageCategories /></PrivateRoutesSuspense>} />
            <Route path="/Students" element={<PrivateRoutesSuspense><Students /></PrivateRoutesSuspense>} />
            <Route path="/Parents" element={<PrivateRoutesSuspense><Parents /></PrivateRoutesSuspense>} />

            <Route path="/ManageSchools" element={ <PrivateRoutesSuspense><ManageSchools /></PrivateRoutesSuspense>} />
            <Route path="/Support" element={<PrivateRoutesSuspense><Support /></PrivateRoutesSuspense>} />
            <Route path="/Support-detail/:id" element={<PrivateRoutesSuspense><SupportDetail /></PrivateRoutesSuspense>} />
            <Route path="/Dashboard" element={ <PrivateRoutesSuspense><Dashboard /></PrivateRoutesSuspense>} />
            <Route path="/Teachers" element={ <PrivateRoutesSuspense><Teachers /></PrivateRoutesSuspense>}  />
            <Route path="/FAQs" element={ <PrivateRoutesSuspense> <FAQs /></PrivateRoutesSuspense>} />
            <Route path="/PushNotifications" element={ <PrivateRoutesSuspense><PushNotifications /></PrivateRoutesSuspense>} />
            <Route path="/ChangePassword" element={<PrivateRoutesSuspense><ChangePassword /></PrivateRoutesSuspense>} />
            <Route path="/ManageAdmins" element={ <PrivateRoutesSuspense><ManageAdmins /></PrivateRoutesSuspense>} />
            <Route path="/ManageContent" element={<PrivateRoutesSuspense><ManageContent /></PrivateRoutesSuspense>} />
            <Route path="/ManageTags" element={<PrivateRoutesSuspense><ManageTags /></PrivateRoutesSuspense>} />
            <Route path="/SchoolProfile/:id" element={<PrivateRoutesSuspense><SchoolProfile /></PrivateRoutesSuspense>} />
            <Route path="/TeacherProfile/:id" element={<PrivateRoutesSuspense><TeacherProfile /></PrivateRoutesSuspense>} />
            <Route path="/TeacherCollection/:id" element={ <PrivateRoutesSuspense><TeacherCollection /></PrivateRoutesSuspense>} />
            <Route path="/StudentClassDetail/:id" element={<PrivateRoutesSuspense><StudentClassDetail /></PrivateRoutesSuspense>} />
            <Route path="/TeacherClassDetail/:id" element={<PrivateRoutesSuspense><TeacherClassDetail /></PrivateRoutesSuspense>} />
            <Route path="/StudentProfile/:id" element={ <PrivateRoutesSuspense><StudentProfile /></PrivateRoutesSuspense>} />
            <Route path="/ParentProfile/:id" element={ <PrivateRoutesSuspense><ParentProfile /></PrivateRoutesSuspense>} />
            <Route path="/StudentCollection/:id" element={ <PrivateRoutesSuspense><StudentCollection /></PrivateRoutesSuspense>} />
            <Route path="/parentCollection/:id" element={ <PrivateRoutesSuspense><ParentCollection /></PrivateRoutesSuspense>} />    
            <Route path="/AdminDetails/:id" element={<PrivateRoutesSuspense><AdminDetails /></PrivateRoutesSuspense>} />
            <Route path="/SingleVideo/:id" element={<PrivateRoutesSuspense><SingleVideo /></PrivateRoutesSuspense>} />

          </Route>

      
          <Route path="*" element={<PageNotFound />} />

        </Routes>
        <Toaster />
      </BrowserRouter>
    </div>
  );
}

export default App;
