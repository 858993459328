import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";
import { constructQueryString } from "../../Util/HelperFunction";


export const manageContentCreateAction = createAsyncThunk(
    'manageContent/create', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('content/create', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export const manageContentListAction = createAsyncThunk(
    'manageContent/list', async (reqBody, thunkAPI) => {
        try {
            const query = constructQueryString(reqBody)
            let url =`content/list?${query}`
            const response = await Api.get(url)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



    export const manageContentUploadVideoAction = createAsyncThunk(
        'manageContent/uploadVideo', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('content/video-upload', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
    
        })
    

    

    export const videoMonthCountAction = createAsyncThunk(
        'manageContent/monthView', async (reqBody, thunkAPI) => {
            try {
                let url =`content/video_monthly_stats?id=${reqBody?.id}&date=${reqBody?.selectedMonth}`
                const response = await Api.get(url)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    
        export const videoWeekCountAction = createAsyncThunk(
            'manageContent/weekView', async (reqBody, thunkAPI) => {
                try {
                    let url =`content/video_weekly_stats?id=${reqBody?.id}&startOfWeek=${reqBody?.date.startOfWeek}&endOfWeek=${reqBody?.date?.endOfWeek}`
                    // let url =`content/video_weekly_stats?id=${reqBody?.id}`
                    const response = await Api.get(url)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        
    

        
        
    
    
    export const manageContentTopVideosListAction = createAsyncThunk(
        'manageContent/topVideos', async (reqBody, thunkAPI) => {
            try {
                const query = constructQueryString(reqBody)
                const response = await Api.get(`content/view/school-stats?${query}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    

    

export const manageContentDetailAction = createAsyncThunk(
    'manageContent/detail', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`content/details?id=${reqBody}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })
    export const resetContentDetailAction = createAction('manageContent/reset');



export const manageContentUpdateAction = createAsyncThunk(
    'manageContent/edit', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`content/edit`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



    

        export const ManageContentDeleteAction = createAsyncThunk(
            'manageContent/delete', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.delete(`content/delete?id=${reqBody.id}`)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
    

    