import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Decryptedid, Encryptedid } from "../Util/BcruptEncyptid";
import moment from "moment";
import ConfirmModal from "../modals/ConfirmModal";
import LoaderComponent from "../Components/common/LoaderComponent";
import UserInfo from "../Hooks/UserInfo";
import WeeklyLoginChart from "../Components/common/WeeklyLoginChart";
import { Form } from "react-bootstrap";
import { convertTime } from "../Util/HelperFunction";
import { parentdetailsAction } from "../Redux/Action/ParentAction";
import AddParentModal from "../modals/AddParentModal";
import WeekPicker from "../Components/common/WeekPicker";
export default function ParentProfile() {
  const {userInfo ,parentDetailPermission } = UserInfo();

  const navigate = useNavigate();
  const [ parentdetails , setParentdetails] = useState({});
  const [loading, setLoading] = useState(true); // State for loading
  const dispatch = useDispatch();

  const { id } = useParams();
  const idval = Decryptedid(atob(id));

  const [WeekCount, setWeekCount] = useState(0);
  const [selectedWeek, setSelecteWeek] = useState("");

const [ParentModal, setParentModal] = useState(false);

const handleParentModalClose = () => {
  setParentModal(false)
}

const handleParentModalOpen = () => {
  setParentModal(true)
}




  const [selecteDates, setSelecteDates] = useState({
    startOfWeek: "",
    endOfWeek: "",
  });

  useEffect(() => {
    setLoading(true);
    dispatch(parentdetailsAction({ id: idval })).then(function (data) {
      setParentdetails(data.payload.data);
      setLoading(false);
    });
  }, [idval,ParentModal]);

  const [selectedType, setSelectedType] = useState(" ");
  const [isModalOpen, setModalOpen] = useState(false);

  const handleResPassword = (type) => {
    setModalOpen(true);
    setSelectedType(type);
  };

  const handleConfirmClose = async () => {
    setModalOpen(false);
  };

  const gotoTeacherProfile = (id) => {
    navigate(`/TeacherProfile/${Encryptedid(id)}`);
  };

  const goToStudentCollection = (ele) => {
    if (ele.createdBy === "Teacher") {
      navigate(`/StudentCollection/${Encryptedid(ele._id)}`, {
        state: ele.teacherId.firstName,
      });
    } else if (ele.createdBy === "School") {
      navigate(`/StudentCollection/${Encryptedid(ele._id)}`, {
        state: ele.schoolId.schoolName,
      });
    } else if (ele.createdBy === "Student") {
      navigate(`/StudentCollection/${Encryptedid(ele._id)}`, {
        state: ele.studentId.firstName,
      });
    } else {
      navigate(`/StudentCollection/${Encryptedid(ele._id)}`, {
        state: "Student",
      });
    }
  };

  const goToStudentClassDetail = (ele) => {
    navigate(`/StudentClassDetail/${Encryptedid(ele._id)}`, {
      state: ele?.teacherId?.firstName,
    });
  };

  const handleSuspend = (type) => {
    setModalOpen(true);
    setSelectedType(type);
  };

  const handleDelete = (type) => {
    setModalOpen(true);
    setSelectedType(type);
  };


  return (
    <Layout>
      {loading ? (
        <LoaderComponent />
      ) : (
        <>
          <div className="right-top">
            <div className="heading-top">
              <p>All Parents / @{parentdetails.firstName} </p>
              <div className="back-page-title">
                <Link to="/Parents">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M13.75 5.5L8.25 11L13.75 16.5"
                      stroke="#CCD2E3"
                      strokeWidth="2"
                    />
                  </svg>
                </Link>
                <h2>
                  @{parentdetails.firstName}
                  <span> {parentdetails.sr_no}</span>
                </h2>
              </div>
            </div>
            <div className="user-side-pro">
              <Link to="">
                {/* <img src={require("../Assets/Images/user.png")} /> */}
                <img
                  src={
                    userInfo?.image
                      ? userInfo?.image
                      : require("../Assets/Images/user.png")
                  }
                  alt="User"
                  style={{
                    borderRadius: "50%",
                    height: 50,
                    width: 50,
                    objectFit: "cover",
                  }}
                />
              </Link>
            </div>
          </div>
          <Container fluid>
            <Row className="pb-5">
              <Col lg={12}>
                <Row>
                  <Col lg={4} className="mb-4 display-table">
                    <div className="school-profile-detail">
                      <div className="card-edit d-flex justify-content-end">
                        <button style={{ border: '0', background: 'unset', padding: '0' }} disabled={!parentDetailPermission} className="text-end pe-3 pt-3 mb-4" onClick={handleParentModalOpen}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_51_2041)">
                                <path
                                  d="M2.25 13.095V15.375C2.25 15.585 2.415 15.75 2.625 15.75H4.905C5.0025 15.75 5.1 15.7125 5.1675 15.6375L13.3575 7.455L10.545 4.6425L2.3625 12.825C2.2875 12.9 2.25 12.99 2.25 13.095ZM15.5325 5.28C15.825 4.9875 15.825 4.515 15.5325 4.2225L13.7775 2.4675C13.485 2.175 13.0125 2.175 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z"
                                  fill="#8F9BBA"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_51_2041">
                                  <rect width="18" height="18" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                         
                        </button>
                      </div>
                      <div className="qr-code">
                        <img
                          className="img-fluid"
                          height={100}
                          width={100}
                          src={
                            parentdetails?.image
                              ? parentdetails?.image
                              : require("../Assets/Images/student.png")
                          }
                        />
                      </div>
                      <div className="">
                        <p className="heavy-text mb-0 ">
                          @{parentdetails.firstName}
                        </p>

                        {parentdetails?.firstName ?
                                <p className="mb-0">{parentdetails?.firstName} {parentdetails?.surName}</p>:
                                <p className="mb-0">N/A</p>
                              }
                        <p className="mb-0">
                          {parentdetails?.schoolId?.schoolName}
                        </p>
                    
                        <p>Key Stage {parentdetails?.group}</p>
                      </div>
                      <div className="three-detail">
                        <div className="info-about-school">
                          <p className="heavy-text mb-0 ">
                            {moment(parentdetails?.createdAt).format(
                              "MMM YYYY"
                            )}
                          </p>
                          <p>Created</p>
                        </div>
                        <div className="info-about-school">
                          <p className="heavy-text mb-0 ">
                            {moment(parentdetails?.updatedAt).format(
                              "MMM YYYY"
                            )}
                          </p>
                          <p>Last Active</p>
                        </div>
                      </div>
                      <div className="mt-4">
                        <p className="mb-2">Contact</p>
                        <p className="heavy-text mb-0 ">
                          E: {parentdetails.email}
                        </p>
                      </div>
                      <div className="action-school-btns">
                        {parentdetails?.isBlocked ? (
                          <button
                            className="reset-pass"
                            onClick={() =>
                              handleSuspend("parentActiveAccount")
                            }
                            disabled={!parentDetailPermission}
                          >
                            Active Account
                          </button>
                        ) : (
                          <button
                            className="reset-pass"
                            onClick={() => handleSuspend("parentSuspend")}
                            disabled={!parentDetailPermission}
                          >
                            Suspend Account
                          </button>
                        )}

                        <button
                          className="reset-pass"
                          onClick={() =>
                            handleResPassword("parentResetPassword")
                          }
                          disabled={!parentDetailPermission}
                        >
                          Reset Password
                        </button>

                        <button
                          className="reset-pass"
                          onClick={() => handleDelete("parentDelete")}
                          disabled={!parentDetailPermission}
                        >
                          Delete Account
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col lg={8} className="mb-4 display-table">
                    <Row>
                      <Col lg={12}>
                        <div className="teacher-search-history mt-0 position-relative">
                          <h2>Collections</h2>

                          <div className="collections-list-pro">
                            <>
                              {Array.isArray(parentdetails?.collectionId) &&
                              parentdetails?.collectionId.length > 0 ? (
                                <>
                                  {parentdetails?.collectionId.map(
                                    (ele, index) => (
                                      <div
                                        onClick={() =>
                                          goToStudentCollection(ele)
                                        }
                                        className="search-history-list collect-box"
                                        key={index}
                                      >
                                        <div className="viewing-h-pro">
                                          <img
                                            src={
                                              ele?.image
                                                ? ele.image
                                                : require("../Assets/Images/user.png")
                                            }
                                          />
                                          <div className="">
                                            <h5>{ele?.name}</h5>
                                            <p className="mb-0">
                                              {moment(ele.updatedAt).format(
                                                "dddd"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </>
                              ) : (
                                <p className="data-not-found-cmn">
                                  Data Not Found
                                </p>
                              )}
                            </>
                          </div>
                        </div>
                      </Col>
                     
                    </Row>
                   
                    <Col lg={12}>
                    {" "}
                    <div className="teacher-search-history ">
                      <h2>Search History</h2>
                      <div className="search-history-list-pro position-relative">
                        {parentdetails &&
                        Array.isArray(parentdetails?.searched) &&
                        parentdetails?.searched?.length > 0 ? (
                          parentdetails?.searched.map((res, index) => {
                            return (
                              <>
                                <div
                                  className="search-history-list"
                                  key={index}
                                >
                                  <h4>{res.searched}</h4>
                                  <p>
                                    {moment(res?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <p className="data-not-found-cmn">Data Not Found</p>
                        )}
                      </div>
                    </div>
                  </Col>
                <Row>
               
                </Row>
            
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={4} className="display-table">
                    <div className="teacher-search-history table-cell">
                      <div className=" position-relative">
                        <div className="">
                          <div className="details-chart">
                            <div>
                              <p>Daily Usage</p>
                              <h5>
                              {convertTime(Math.trunc(WeekCount))} <span>Average</span>
                              </h5>
                            </div>
                             <WeekPicker setSelectedDates={setSelecteDates} type="TopCategories" />
                          </div>
                          <WeeklyLoginChart
                            setWeekCount={setWeekCount}
                            selecteDates={selecteDates}
                            selectedWeek={selectedWeek}
                            type="parent"
                            idval={idval}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={8} className="display-table">
                    <div className="teacher-search-history table-cell">
                      <h2>Recent Viewing History</h2>
                      <div className="viewing-history-list-scrol position-relative">
                        {Array.isArray(parentdetails.history) &&
                        parentdetails?.history.length > 0 ? (
                          parentdetails.history?.map((res, index) => {
                            return (
                              <>
                                <div
                                  className="search-history-list"
                                  key={index}
                                >
                                  <Link
                                    to={
                                      "/SingleVideo/" +
                                      Encryptedid(res?.video?._id)
                                    }
                                  >
                                    <div className="viewing-h-pro">
                                      <img
                                        src={
                                          res?.video?.cover_url
                                            ? res.video.cover_url
                                            : null
                                        }
                                      />
                                      <div className="">
                                        <h5>{res?.video?.title}</h5>
                                        <p className="mb-0">
                                          {" "}
                                          {res?.video?.categoryId?.title}
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                  <p>
                                    {res?.createdAt
                                      ? moment(res?.createdAt).format(
                                          "DD/MM/YYYY"
                                        )
                                      : null}
                                  </p>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <p className="data-not-found-cmn">Data Not Found</p>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Col lg={12}>
                <Row>
                  <Col lg={4}>
                    {" "}
                    <div className="teacher-search-history ">
                      <h2>Search History</h2>
                      <div className="search-history-list-pro position-relative">
                        {parentdetails &&
                        Array.isArray(parentdetails?.searched) &&
                        parentdetails?.searched?.length > 0 ? (
                          parentdetails?.searched.map((res, index) => {
                            return (
                              <>
                                <div
                                  className="search-history-list"
                                  key={index}
                                >
                                  <h4>{res.searched}</h4>
                                  <p>
                                    {moment(res?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <p className="data-not-found-cmn">Data Not Found</p>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </Container>
        </>
      )}

      <ConfirmModal
        data={idval}
        isModalOpen={isModalOpen}
        selectedType={selectedType}
        handleConfirmClose={handleConfirmClose}
      />

<AddParentModal
        ParentModal={ParentModal}
        handleParentModalClose={handleParentModalClose}
        typeModal="editParent"
        EditData={parentdetails}
        idval={idval}

      />
    </Layout>
  );
}






