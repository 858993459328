import { createSlice } from "@reduxjs/toolkit";
import { avatarAction, parentClassListAction, parentCollectionListAction, parentCreateAction, parentCsvListAction, parentDeleteAction, parentEditAction, parentListAction, parentResetPassAction, parentSuspendAction, weelklyLoginparentAction } from "../Action/ParentAction";

const parentSlice = createSlice({
    name: "parent",
    initialState: {
        parent: [],
        parentResetPassStatus: "idle",
        parentCollectionListStatus:"idle",
        parentClassListStatus:"idle",
        parentSuspendStatus:"idle",
        parentDeleteStatus:"idle",
        weelklyLoginparentStatus:"idle",
        parentCreateStatus:"idle",
        parentCsvListStatus:"idle",
        parentEditStatus:"idle",
        avatars:{}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(parentListAction.fulfilled, (state, action) => {
                state.parent = action?.payload?.data;
            })
            .addCase(parentResetPassAction.fulfilled, (state, action) => {
                state.parentResetPassStatus = 'succeeded';
            })
            .addCase(parentCollectionListAction.fulfilled, (state, action) => {
                state.parentCollectionListStatus = 'succeeded';
            })
          
            .addCase(parentClassListAction.fulfilled, (state, action) => {
                state.parentClassListStatus = 'succeeded';
            })

            
            .addCase(parentSuspendAction.fulfilled, (state, action) => {
                state.parentSuspendStatus = 'succeeded';
            })

            .addCase(parentDeleteAction.fulfilled, (state, action) => {
                state.parentDeleteStatus = 'succeeded';
            })

            
            .addCase(weelklyLoginparentAction.fulfilled, (state, action) => {
                state.weelklyLoginparentStatus = 'succeeded';
            })

            .addCase(parentCreateAction.fulfilled, (state, action) => {
                state.parentCreateStatus = 'succeeded';
            })    
            

            .addCase(parentEditAction.fulfilled, (state, action) => {
                state.parentEditStatus = 'succeeded';
            })    
            

            .addCase(parentCsvListAction.fulfilled, (state, action) => {
                state.parentCsvListStatus = 'succeeded';
            })    
            

            .addCase(avatarAction.fulfilled, (state, action) => {
                state.avatars = action?.payload?.data;
            })    
            
            
    }
})

export default parentSlice.reducer;