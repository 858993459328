import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const DashboardListAction = createAsyncThunk(
    'dashboard/list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/auth/dashboard?schoolId=${reqBody.schoolId}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



// export const RegistrationGraphAction = createAsyncThunk(
//     'dashboard/registration', async (reqBody, thunkAPI) => {
//         try {
//             const response = await Api.get(`/auth/data-visualization?type=${reqBody?.type}`)
//             return response.data
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     })

export const RegistrationGraphAction = createAsyncThunk(
    'dashboard/registration',
    async (reqBody, thunkAPI) => {
        try {
            const { type, schoolId } = reqBody;
            const url = `/auth/data-visualization?type=${type}${schoolId ? `&schoolId=${schoolId}` : ''}`;
            const response = await Api.get(url);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);



// export const dashboardTopVideosAction = createAsyncThunk(
//     'dashboard/topVideos', async (reqBody, thunkAPI) => {
//         try {
//             const response = await Api.get(`/auth/dashboard/videos?startOfWeek=${reqBody?.startOfWeek}&endOfWeek=${reqBody?.endOfWeek}`)
//             return response.data
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     })


// export const dashboardTopCategoryAction = createAsyncThunk(
//     'dashboard/topCategory', async (reqBody, thunkAPI) => {
//         try {
//             const response = await Api.get(`/auth/dashboard/categories?startOfWeek=${reqBody?.startOfWeek}&endOfWeek=${reqBody?.endOfWeek}`)
//             return response.data
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     })


// export const dashboardTopSavedAction = createAsyncThunk(
//     'dashboard/topSaved', async (reqBody, thunkAPI) => {
//         console.log(reqBody, "sddddddddddd333")
//         try {
//             const response = await Api.get(`/auth/dashboard/save-videos?startOfWeek=${reqBody.startOfWeek}&endOfWeek=${reqBody?.endOfWeek}`)
//             return response.data
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     })
  

  export const dashboardTopSavedAction = createAsyncThunk(
    'dashboard/topSaved',
    async (reqBody, thunkAPI) => {
        try {
            const { startOfWeek, endOfWeek, schoolId } = reqBody;

            const response = await Api.get(`/auth/dashboard/save-videos?startOfWeek=${startOfWeek || ''}&endOfWeek=${endOfWeek || ''}&schoolId=${schoolId || ''}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


// export const dashboardTopTagsAction = createAsyncThunk(
//     'dashboard/topTags', async (reqBody, thunkAPI) => {
//         try {
//             const response = await Api.get(`/auth/dashboard/tags?startOfWeek=${reqBody.startOfWeek}&endOfWeek=${reqBody?.endOfWeek}&schoolId=`)
//             return response.data
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     })

// export const dashboardTopActivityAction = createAsyncThunk(
//     'dashboard/topActivity', async (reqBody, thunkAPI) => {
//         try {
//             const response = await Api.get(`/auth/dashboard/group-activity?&startOfWeek=${reqBody.startOfWeek}&endOfWeek=${reqBody?.endOfWeek}`)
//             return response.data
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     })



export const dashboardTopVideosAction = createAsyncThunk(
    'dashboard/topVideos', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/auth/dashboard/videos?startOfWeek=${reqBody?.startOfWeek}&endOfWeek=${reqBody?.endOfWeek}&schoolId=${reqBody?.schoolId}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const dashboardTopCategoryAction = createAsyncThunk(
    'dashboard/topCategory', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/auth/dashboard/categories?startOfWeek=${reqBody?.startOfWeek}&endOfWeek=${reqBody?.endOfWeek}&schoolId=${reqBody?.schoolId}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const dashboardTopTagsAction = createAsyncThunk(
    'dashboard/topTags', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/auth/dashboard/tags?startOfWeek=${reqBody?.startOfWeek}&endOfWeek=${reqBody?.endOfWeek}&schoolId=${reqBody?.schoolId}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const dashboardTopActivityAction = createAsyncThunk(
    'dashboard/topActivity', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/auth/dashboard/group-activity?startOfWeek=${reqBody?.startOfWeek}&endOfWeek=${reqBody?.endOfWeek}&schoolId=${reqBody?.schoolId}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
