import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";
import { constructQueryString } from "../../Util/HelperFunction";

export const notificationCreateAction = createAsyncThunk(
    'notification/create', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('notification/create', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const resetNotificationtAction = createAction('support/reset');




export const notificationListAction = createAsyncThunk(
    'notification/list', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`notification/list${queryString ? `?${queryString}` : ""}`, reqBody)
            // const response = await Api.get(`notification/list?search=${reqBody?.searchValue || ""}&user=${reqBody?.user}&group=${reqBody?.group}&gender=${reqBody?.gender}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



export const notificationEditAction = createAsyncThunk(
    'notification/edit', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put('notification/edit', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export const notificationDeleteAction = createAsyncThunk(
    'notification/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`notification/delete?id=${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



