import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const signinAction = createAsyncThunk(
    'signin', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/auth/login', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })
export const verifyOtpAction = createAsyncThunk(
    'verify', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/auth/verify', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


export const ResetPasswordAction = createAsyncThunk(
    'resetpassword', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/auth/forgot-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

export const forgotverifyEmailAction = createAsyncThunk(
    'forgot-verifyEmail', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/auth/forgot-verifyEmail', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


export const resetpasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put('/auth/reset-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

export const adminResetPasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put('/sub/new-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

export const adminSubResetPasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/sub/reset-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


export const changePasswordAction = createAsyncThunk(
    'changePassword', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put('/profile/changePassword', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })












