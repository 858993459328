import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FaCalendarAlt } from "react-icons/fa";

const WeekPicker = ({ setSelectedDates }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleWeekChange = (date) => {
    if (date) {
      setSelectedDate(date);

      const startOfWeek = moment(date).startOf("isoWeek").format("YYYY-MM-DD");
      const endOfWeek = moment(date).endOf("isoWeek").format("YYYY-MM-DD");

      setSelectedDates({
        startOfWeek,
        endOfWeek,
      });
    } else {
      // Clear data when no date is selected
      setSelectedDate(null);
      setSelectedDates({
        startOfWeek: "",
        endOfWeek: "",
      });
    }
  };

  return (
    <div>
      <DatePicker
        selected={selectedDate}
        dateFormat="I/R"
        locale="en-GB"
        showWeekNumbers
        showWeekPicker
        onChange={handleWeekChange}
        className="form-control"
        placeholderText="Select Week"
        isClearable={true}
        customInput={
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaCalendarAlt style={{ color: "#6c757d" }} />
            <input
              type="text"
              value={
                selectedDate
                  ? `Week ${moment(selectedDate).isoWeek()}, ${moment(selectedDate).year()}`
                  : ""
              }
              placeholder="Select Week"
              readOnly
              style={{ border: "none", paddingLeft: "10px" }}
            />
          </div>
        }
      />
    </div>
  );
};

export default WeekPicker;
