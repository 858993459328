import { createSlice } from "@reduxjs/toolkit";
import { resetsupportAction, supportDetailAction, supportDetailAddNotesAction, supportDetailStatusAction, supportListAction } from "../Action/SupportAction";



const initialState = {
    supportList:{},
    supportDetail:{},
    supportDetailStatus:"idle",
    supportDetailAddNotesStatus:"idle"
}

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(supportListAction.fulfilled, (state, action) => {
                state.supportList = action?.payload?.data;  
            })     
           
            .addCase(supportDetailAction.fulfilled, (state, action) => {
                state.supportDetail = action?.payload?.data;  
            })     

            .addCase(supportDetailStatusAction.fulfilled, (state, action) => {
                state.supportDetailStatus = 'succeeded';
            })     
            

            .addCase(supportDetailAddNotesAction.fulfilled, (state, action) => {
                state.supportDetailAddNotesStatus = 'succeeded';
            })     
            

            
        
            .addCase(resetsupportAction, () => initialState);

    },
});

export default supportSlice.reducer;








