import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginUserInfoAction } from '../Redux/Action/ManageAdminAction';


const UserInfo = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    dispatch(LoginUserInfoAction()).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);
  

  const userInfo = useSelector((state) => state.manageAdminsSlice.userInfo);
  const permission = userInfo?.access?.full
  const contentPermission = userInfo.contentPermission
  const studentDetailPermission = userInfo?.studentDetailPermission
  const schoolDetailPermission = userInfo?.schoolDetailPermission
  const parentDetailPermission = userInfo?.parentDetailPermission
  const categoriesPermission = userInfo?.categoriesPermission
  const tagPermission = userInfo?.tagPermission
  const faqPermission = userInfo?.faqPermission
  const supportPermission = userInfo?.supportPermission
  const notificationPermission = userInfo?.notificationPermission
  const adminPermission = userInfo?.adminPermission


  return {
     isLoading, 
     userInfo,
     permission,
     contentPermission,
     studentDetailPermission,
    schoolDetailPermission,
    parentDetailPermission,
    categoriesPermission,
    tagPermission,
    faqPermission,
    supportPermission,
    notificationPermission,
    adminPermission,
  };
};

export default UserInfo;
