import { configureStore } from '@reduxjs/toolkit';
import schoolSlice from './Slice/schoolSlice';
import categoryslice from './Slice/categorySlice';
import studentSlice from './Slice/studentSlice';
import manageContentSlice from './Slice/manageContentSlice';
import tagsSlice from './Slice/tagsSlice.';
import faqSlice from './Slice/faqSlice';
import teacherSlice from './Slice/teacherSlice';
import manageAdminsSlice from './Slice/manageAdminsSlice';
import supportSlice from './Slice/supportSlice';
import notificationSlice from './Slice/notificationSlice';
import dashboardSlice from './Slice/dashboardSlice';
import parentSlice from './Slice/parentSlice';



const store = configureStore({
  reducer: {
    school: schoolSlice,
    category: categoryslice,
    student: studentSlice,
    manageContentSlice, manageContentSlice,
    tagsSlice: tagsSlice,
    faqSlice: faqSlice,
    teacherSlice: teacherSlice,
    manageAdminsSlice: manageAdminsSlice,
    supportSlice:supportSlice,
    notificationSlice:notificationSlice,
    dashboardSlice:dashboardSlice,
    parentSlice:parentSlice,
  }
});

export default store;